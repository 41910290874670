import EntityItem from '../entity-item/EntityItem.vue'

 export default {
   name: 'EntityMentions',
   components: {
     EntityItem
   },
   props: [
    'mentions',
    'onlyValid',
    'all_entities',
  ],
   methods: {
       entity_selected(v)
       {
           
           this.$emit('selected-entity-finished',v )
       },
       update_entities(){
           for (var ref in this.$refs) {
            var thisref = this.$refs[ref]
            if   (typeof thisref.updateProperties === "function") {                  
                thisref.updateProperties()    
                thisref.showdetails = false
            }
           }

       }
   }
 }
import { mapState } from 'vuex';
import RelationItem from './relation-item/RelationItem.vue'
import AddRelation from './add-relation/AddRelation.vue'
import {add_relation_mention} from '@/api/ontologias'

export default {
  name: 'Relations',
  props: ["data"],
  components: {
    RelationItem,
    AddRelation
  },
  computed: mapState([
    'class_list', 
    "entities_by_class_with_label",
    "doc_uri",
    "existing_entity_mentions",
    "existing_relation_mentions"
  ]),

  data () {
    return {
      showSection: true, // Flag to show section
      toggleIcon: '+',
      firstdata: [],
      show_green_button: false,
      onlyValidMentions: false,
      invalidator: false,
      writter: false,
    }
  },
  created() {
    const roles = (localStorage.getItem('angelus-roles') || '').split(',')
    this.invalidator = roles.indexOf('invalidador') > -1
    this.writter = localStorage.getItem('angelus-writter') === 'true';
  },
  async mounted () {
    this.update_green_button()
  },
 
  methods: {
    toggle() {
      this.showSection = !this.showSection
      },
      async sendAllToAPI()
      {
          var EntitiesToRefresh = new Set()
          let AllPromises = []
          var px;
          console.log("|--Starging Relation Mentions--|")
          for (var menum in this.existing_relation_mentions){
              var men = this.existing_relation_mentions[menum]
              if (men["pre_existing"] || men["alreadySent"]){
                  continue
              }
              var tosend = {"location":{}}
              tosend.location.soporte_documental = this.doc_uri
              tosend.specific_provenance = men.specific_provenance
              tosend.predicate = men.predicate
              tosend.object = men.object
              tosend.subject = men.subject                
              //console.log("Will post-----")
              //console.log(tosend)
              px = await add_relation_mention(tosend)
                .then(rAPI => {men.alreadySent = rAPI.status==200 
                               //console.log(rAPI.data)
                               men.relationURI = rAPI.data.new_relation.uri  
                               EntitiesToRefresh.add(men.object) 
                               EntitiesToRefresh.add(men.subject)   
                               })
              AllPromises.push(px)
          }
          Promise.all(AllPromises)
            .then(x=>{
                console.log("|--Finishing Relation Mentions --| "+x)
                if (EntitiesToRefresh.size>0){                    
                  this.$emit("reload-entities",EntitiesToRefresh)                
                }
                this.reload_values()
                this.update_green_button()
            })
          
      },
      reload_values()
      {   
          this.$refs.relAdder.getSubjects()
      },
      add_mention(v)
      {
          this.$store.dispatch('add_relation_mention', v)
          this.$refs.relAdder.clear_inputs()
          this.update_green_button()
      },
      update_list_of_mentions(newlist){
          this.$store.dispatch("clear_relation_mentions")
          for (var men in newlist) {
             var newent = newlist[men]               
             this.$store.dispatch('add_relation_mention',newent)
          }
      },
      remove_mention(id){
          this.$store.dispatch('rem_relation_mention',id)
          this.update_green_button()
      },
      update_green_button(){
          this.show_green_button = false
          for (var menum in this.existing_relation_mentions){
              var men = this.existing_relation_mentions[menum]
              if (!men.alreadySent){
                  this.show_green_button = true
                  break
              }
          }
       },
  },
}
import { del_invalid_annotation } from "@/api/ontologias"

export default {
  name: "RelationItem",
  props: ["item_data"],
  data() {
    return {        
       showdetails : false,
       invalidator: false,
    }
  },
  methods: {
      entity_selected(v)  {
          
          this.$emit('selected-entity-finished',v )
      },
      toggle_details() {
      this.showdetails = !this.showdetails
    },
    async invalidMention(id) {
      console.log(id)
      await del_invalid_annotation(id)
      this.item_data.valid_annotation = false
     }
  },
  created() {
    const roles = (localStorage.getItem('angelus-roles') || '').split(',')
    this.invalidator = roles.indexOf('invalidador') > -1
  }
}
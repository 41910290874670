import { mapState } from 'vuex'
import vSelect from 'vue-select'

  
export default {
  name: 'AtomicInput',
  props: ["input_type", 
          "range_uri",
          "prop_label"],
  data(){
      return {
            object_this : null,
            selector_value : null,   
            selector_value2 : null,
            show_input : true,
            datetime_label : "",
            first_options: [],
            second_options: [],
            second_visible: false,
            placeholder_2: "especifica",
            placeholder_1: "elige",

            activePicker: null,
            menu: false,
    }
  },
  watch: {
    menu (val) {
      val && setTimeout(() => (this.activePicker = 'YEAR'))
    },
  },
  created(){
      if (this.input_type=='datetime')
      {
          this.clearinput()
      }
      if (this.input_type=="catalogue")
      {
          this.set_options(this.input_type, this.range_uri, "first")
      }
  },
  methods: {
    save (date) {
        this.$refs.menu.save(date)
        this.updateText()
    },
    async set_options(itype, ruri, options_list_name) {
        var r = []
        var toreturn =[]    
        if (options_list_name=="first")        {
            this.first_options = []
            this.second_options = []
        }
        if (options_list_name=="second"){
            this.second_options = []
        }

        

        //console.log("!==> setting optinos of type "+this.input_type+" \trangeuri:"+ruri)         
        //console.log("itype "+itype+"  ruri"+ruri+ "  <=="+options_list_name)
        
        if (itype=="catalogue")
            { 
            var mykeys = Object.keys(this.catalogues)

            if (ruri in mykeys 
                    && this.catalogues[ruri] !== undefined
                    && this.catalogues[ruri].length > 0 ){
                console.log("In existing catalogues.")
                r = this.catalogues[ruri]            
            }
            else {            
                console.log(ruri+" not in catalogues at the moment")
                toreturn = [{"id":-1, "label":" ... cargando, porfavor espere..."}]
                await this.$store.dispatch('get_narrowers',ruri)    
                r = this.catalogues[ruri]    

            } 
            console.log("IN THEORY NARROWERS ARE HERE")
            toreturn = []

            this.second_visible=false
            for (var trn in r)  {
                var tr = r[trn]
                toreturn = [... toreturn, {"id": tr['uri'], 
                                "label": tr["label"]}]

                if (options_list_name=="first"){
                    this.first_options = toreturn   
                    this.first_options.sort()                 
                }
                if (options_list_name=="second"){
                    this.second_options = toreturn
                    this.second_visible = toreturn.length > 0
                    this.second_options.sort()
                }
            }    
            
        }
        
        
        return toreturn
    },
    updateSelect(v) {
        this.selector_value = v
        this.$emit("InputValueChanged", v.id)
    
        this.second_visible = false
        this.second_options = []
        //this.second_options("catalogue",v.id, this.second_options)
        this.set_options("catalogue",v.id,"second")
    },
    updateSelect2(v) {
        this.selector_value2 = v            
        this.$emit("InputValueChanged", v.id)          
    },
    updateText() {
        this.$emit("InputValueChanged", this.object_this)
    },
    plabel() {
        return this.prop_label
    },
    clearinput() {
        this.object_this = null
        this.selector_value = null
        /*if (this.input_type=='datetime') {
          this.object_this = "1975-01-01T12:20:00.001Z"
          this.show_input = false
          this.datetime_label = "Seleccionar fecha"
        }*/
        
    },
  },
  computed: mapState([
        'propertyrangetypes',
        'property_labels',
        'property_ranges', 
        'catalogues'           
    ]),
  components: {
    'vue-select': vSelect,
    },
}

import DocumentVisualizer from './document-visualizer/DocumentVisualizer.vue'
import Entities from './entities/Entities.vue'
import Information from './information/Information.vue'
import Relations from './relations/Relations.vue'

import { 
  api_doc_get,
  base_doc_uri,
  // default_doc_id,
  get_doc_metadata,
  get_info_on_entities,
  get_mentions_in_document,
  sleep,
} from '@/api/ontologias'

import { mapState } from 'vuex'


export default {
  name: 'Annotator',
  components: {
    DocumentVisualizer,
    Entities,
    Information,
    Relations,
  },
  data () {
    return {
      docId: String,
      metadata: null,
      panel: [0, 1, 2],

      firstdata: [    ],
      test_array: [],      
      testsection_1: {"title": "Documento","content": ""},
      testsection_2: {"title": "Entidades","content": ""},
      testsection_3: {"title": "Relaciones","content": ""}, 
      urls: {
        "get_doc":"https://apidocumentos.pruebascnb.org/document?docid="
      },
      class_list_o  : [{id: 1, name:"bla"}],
      show_reload_spash: false,
      splash_message: "Cargando",
      pre_existing_relmens : {},
      pre_existing_entmens : {},
      docmetadata : {},
      original_doc_uri : ""
    }
  },

  computed: mapState([
    'class_list', 
    'entities_by_class_with_label',
    'property_labels',
    'entity_labels',
    'all_entity_info'
  ]),

  mounted () {
    this.docId = this.$route.params.docid
    if (this.docId.includes("/"))
    {
        this.docId = this.docId.replace(/\//,"%2F")
    }
    this.$store.dispatch('set_docid',this.docId)
    this.get_metadata()


    this.show_reload_spash = true
        this.put_message("Inicializando", 10)        
        var docuri = this.get_doc_uri()
        var mentloading = get_mentions_in_document(docuri)     
            .then(x=> {
              console.log('**** resp', x);       
                var entityids = []
                for (var entmn in x["entments"]){
                    var en = x["entments"][entmn]
                    entityids.push(en["entity_id"])
                }            
                this.pre_existing_relmens = x["relments"]
                this.pre_existing_entmens = x["entments"]
                return entityids})
            .then(entityids => this.load_info_on_entities(entityids))            

        var docmdloading = get_doc_metadata(docuri)
            .then(x=>{this.docmetadata = {
                "title": x["<https://ontologias.pruebascnb.org/v1.0/tieneTitulo>"],
                "foliaciones": x["<https://ontologias.pruebascnb.org/v1.0/tieneFoliacion>"],
                "padre": x[ "<https://ontologias.pruebascnb.org/v1.0/perteneceA>"]}
                })

        var classloading =  this.$store.dispatch('loadClasses').then(c=>this.$store.dispatch('populateClasses',c) )                  
        Promise.all([classloading, mentloading, docmdloading])
            .then(x=>{
                console.log("--Loading Mentions Into Lists: ")  
                this.$refs.entsSection.update_list_of_mentions(this.pre_existing_entmens)
                this.$refs.relsSection.update_list_of_mentions(this.pre_existing_relmens)
                this.$refs.relsSection.reload_values()
                this.clean_pre_relations() 
                this.show_reload_spash = false
                this.del_message()
                console.log("finished init "+x)})
  },

  methods: {
    get_doc_uri () { 
      return `<${base_doc_uri}${this.docId}>`   
  
    },
    async get_metadata () {
      const metadata = await get_doc_metadata(this.get_doc_uri())

      this.metadata = {
        title: metadata['<https://ontologias.pruebascnb.org/v1.0/tieneTitulo>'],
        foliaciones: metadata['<https://ontologias.pruebascnb.org/v1.0/tieneFoliacion>'],
        padre: metadata['<https://ontologias.pruebascnb.org/v1.0/perteneceA>'],
        paginas: metadata['<http://purl.org/dc/terms/hasPart>'],
        tipoDocumento: metadata['Content-Type'] ?? 'application/pdf',
      }
    },


    clean_pre_relations(){
      for (var permn in this.pre_existing_relmens){
          var pl = this.property_labels[this.pre_existing_relmens[permn]["predicate"]]
          this.pre_existing_relmens[permn]["predicate_label"] = pl
          
          var ol = this.entity_labels[this.pre_existing_relmens[permn]["object"]]
          this.pre_existing_relmens[permn]["object_label"] = ol

          var sl = this.entity_labels[this.pre_existing_relmens[permn]["subject"]]
          this.pre_existing_relmens[permn]["subject_label"] = sl
      }
    },
    get_doc_url() {                    
      var url = api_doc_get+this.get_doc_uri()             
      return url
    },
async load_info_on_entities(entityidlist_o){

    let entityidlist = [... new Set(entityidlist_o)]
    if (entityidlist.length<1){
        return true
    }
    console.log("loading "+entityidlist.length+" entities at once")        
    return await get_info_on_entities(entityidlist)
      .then(r => {
          var c = 0
          for (var eid of Object.keys(r)){                  
              var entdata = r[eid]
              this.$store.dispatch('set_entity_info', [eid, entdata])
              c+=1
          }
          console.log("loaded all "+c)
          this.$refs.relsSection.reload_values()   
         }
      )
    
    },
async put_message(msg, minduration)
{
    await sleep(minduration)
    this.show_reload_spash=true
    this.splash_message=msg
},
async del_message()
{
    this.show_reload_spash=false
    this.splash_message=""
},
async reloader(setclasses,delay, initialdelay){
    console.log(setclasses)
    if (setclasses.length > 0){
        this.show_reload_spash=true
        this.splash_message="Recargando clases"
        await sleep(initialdelay)
        for (let item of setclasses){
            await sleep(delay)
            this.$store.dispatch('populateSpecificClass',item)              
        }                    
        this.$refs.relsSection.reload_values()
        console.log("\n----Clases recargadas---- \n")
        console.log(setclasses)
    }
    this.show_reload_spash=false
},
  },
}

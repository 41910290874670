import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
import PropertyList from '../property-list/PropertyList.vue'
import DataPropertyEditor from '../entity-data-property-editor/EntityDataPropertyEditor.vue'

import { mapState } from 'vuex';
import { orderdict, get_info_on_entity, del_invalid_annotation } from "@/api/ontologias"

export default {
  name: 'EntityItem',
  props: [
    'item_data',
    'onlyValid',
  ],
  computed: mapState([
    'class_list', 
    'entities_by_class_with_label',
    'all_entity_info'
  ]),
  components: {
    'VueSelect': vSelect,
    PropertyList,
    DataPropertyEditor,
  },
  data() {
     return {
        selected: '',
        entities_same_class: [ ],
        shouldRenderOK: false,
        entitys_properties : [],
        entitys_relations :[],
        confirmed: false,
        showdetails : false,
        showPropertyEditor: true,
        loadingmessage: "",
        default_selectvalue: {'id':1,'longname':"---"},
        selector_value: null,
        add_new_properties: false,
        placeholder_entity: {},
        invalidator: false,
     }
   },
   methods: {
     toggle_details() {
       this.showdetails = !this.showdetails
       this.updateProperties()
     },
     show_property_editor(){
         return (this.add_new_properties && this.item_data.had_properties_altered)
     },
     shouldWeDisplayButton(){
       if (this.item_data.properties == null ) { 
           this.shouldRenderOK=false
           return 
           }
        if (this.item_data.properties.length<1){
            this.shouldRenderOK=false
            return
        }        
        for (var pn in this.item_data.properties){
            var p = this.item_data.properties[pn]
            {                                                
                if (p.predicate in orderdict)
                {                    
                    this.shouldRenderOK = true
                    this.item_data.entity_label = p["literal"]
                    return
                }
            }
        }
        
         
        this.shouldRenderOK = false
        
     },
     updateSelect(v){
       this.selector_value = v
       if (v.id.length > 0){
         this.shouldRenderOK = true
         this.item_data.entity_id = v.id
         this.item_data.entity_label = v.name
       }
       else {
         this.shouldRenderOK = false
       }
        this.updateProperties()
     },
     async updateProperties()
     {
       this.entitys_properties = []
       this.entitys_relations = []
       this.loadingmessage = "Cargando...."
       if (this.item_data.entity_id !=null && this.item_data.entity_id != "NEW"){        
         var entitydata = {"data_properties":[], "object_properties": []};
         var eid = this.item_data.entity_id
         
         if (this.all_entity_info[eid] == null) {
            await get_info_on_entity(eid)
            .then(res => {entitydata=res;
                          this.$store.dispatch('set_entity_info', [eid, entitydata])})            
         } else {
             entitydata = this.all_entity_info[eid]
             
         }         
         //console.log(entitydata)
         this.entitys_properties = entitydata["data_properties"]
         this.entitys_relations  = entitydata["object_properties"]
         console.log('**** entities', this.entitys_properties)
       }
       else {
         this.entitys_properties = []
         this.entitys_relations = []
       }
       this.loadingmessage = ""
     },
     switch_to_display_mode()
     {
         if (this.item_data.entity_id !=null){
            this.confirmed = true                    
        }
        //this.$emit('selected-entity-finished',    this.item_data.mention_id)
     },
     turn_all_green(){
         console.log(this.$refs.additionalPropEditor)
         this.$refs.additionalPropEditor.turn_all_green()
         this.toggle_details()
     },
     finished_adding_existing()
     {
         this.$emit('selected-entity-finished',    this.item_data)
     },
     start_adding_extra_properties(){         
         this.item_data.additional_properties = []
         this.item_data.had_properties_altered = true
         this.placeholder_entity = {"class_id": this.item_data.class_id}
         this.add_new_properties = true
     },
     updated_extra_properties(){
         this.item_data.additional_properties = []
         for (var prn in this.placeholder_entity.properties)
         {
            //console.log(prn)
            this.item_data.additional_properties = [... this.item_data.additional_properties, this.placeholder_entity.properties[prn]]
         }

     },
     cancel_adding_extra_properties(){
         this.item_data.had_properties_altered = false
         this.placeholder_entity = {"class_id": this.item_data.class_id}
         this.item_data.additional_properties = []
         this.add_new_properties = false
         this.toggle_details()
     },
     finished_adding_new()
     {
         this.item_data.entity_id = "NEW"
         this.showPropertyEditor = false
         this.$emit('selected-entity-finished',    this.item_data)
         //this.switch_to_display_mode()
     },
     update_sameclass(aclass){        
        this.entities_same_class = this.entities_by_class_with_label[aclass]            
        this.selector_value = null
     },
     reset_values(){
        this.selected= ''
        this.entities_same_class= [ ]
        this.shouldRenderOK= false
        this.entitys_properties= []
        this.entitys_relations=[]
        this.confirmed= false
        this.showdetails= false
        this.showPropertyEditor= true
        this.loadingmessage= ""
        this.default_selectvalue= {'id':1,'longname':"---"}
        this.selector_value= null
        this.add_new_properties = false
     },
     async invalidMention(id) {
      console.log(id)
      await del_invalid_annotation(id)
      this.item_data.valid_annotation = false
     }
   },
   created() {
     const roles = (localStorage.getItem('angelus-roles') || '').split(',')
     this.invalidator = roles.indexOf('invalidador') > -1

     this.update_sameclass(this.item_data.class_id)
     this.updateProperties()
     if (this.item_data.pre_existing != null && this.item_data.pre_existing){
         this.switch_to_display_mode()
     }
     if (this.item_data.confirmed){
        this.confirmed = this.item_data.confirmed
     }
     console.log('**ITEM DATA', this.item_data)
   }

 }
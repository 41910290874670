import EntityMentions from './entity-mentions/EntityMentions.vue'
import AddEntity from './add-entity/AddEntity.vue'
import { mapState } from 'vuex'
import { 
  add_existing_entity_mention,
  add_new_entity_mention,
  add_data_properties_to_entity
} from '@/api/ontologias'

export default {
  name: "Entities",
  components: {
    EntityMentions,
    AddEntity,
  },
  computed: mapState([
    "class_list",
    "entities_by_class_with_label",
    "doc_uri",
    "class_labels",
    "propertyrangetypes",
  ]),
  props: { titledata: Object },
  methods: {
    toggle() {
      this.showSection = !this.showSection;
    },
    async sendAllToAPI() {
      console.log("|--Starting Sendg To Api ---|");
      this.$emit("show-message", "Subiendo Anotaciones", 0);

      var ClassesToRefresh = new Set();
      var EntitiesToRefresh = new Set();
      let AllPromises = [];
      var px;
      var prx;
      for (var men in this.existing_entity_mentions) {
        var me = this.existing_entity_mentions[men];
        if (me.already_added) {
          continue;
        }
        var pro = [];
        var obprops = []
        var pron = 0;
        var pr = null;
        // ----------------- Mention of EXISTING entity ------------------------
        // ---------------------------------------------------------------------
        if (!me.new) {
          px = await add_existing_entity_mention(me.entity_id, this.doc_uri)
            .then((rAPI) => (me.already_added = rAPI.status == 200))
            .then(this.$store.dispatch("add_entity_mention", me))
            .then(EntitiesToRefresh.add(me.entity_id))
            .then(Promise.resolve("Existing " + me.entity_id));
          AllPromises.push(await px);

          // In case there are additional properties to add:
          if (me.additional_properties != null) {
            console.log("Have to patch entity:");
            console.log(me);

            // This we do in order to remove other properties which are not being used
            for (pron in me.additional_properties) {
              pr = me.additional_properties[pron];
              prx = {
                predicate: pr["predicate"],
                specific_provenance: pr["specific_provenance"],
              };
              if (this.propertyrangetypes[pr["predicate"]] == "literal") {
                prx["literal"] = pr["literal"];
                pro = [...pro, prx];
              }
              if (this.propertyrangetypes[pr["predicate"]] == "catalogue") {
                prx["object"] = pr["literal"];
                obprops = [...obprops, prx]
              }
              
            }
            console.log(pro);
            px = await add_data_properties_to_entity(
              me.entity_id,
              pro, obprops,
              this.doc_uri
            )
              .then((rAPI) => {
                me.already_added = rAPI.status == 200;
                me.additional_properties = [];
                me.had_properties_altered = false;
                console.log("patched entity....");
                console.log(me);
              })
              .then(Promise.resolve("Patching " + me.entity_id));
            AllPromises.push(px);
          }
        }
        // ----------------- Mention of NEW entity -----------------------------
        // ---------------------------------------------------------------------
        else {
          // This we do in order to remove other properties which are not being used
          for (pron in me["properties"]) {
            pr = me["properties"][pron];
              prx = {
                predicate: pr["predicate"],
                specific_provenance: pr["specific_provenance"],
              };
              if (this.propertyrangetypes[pr["predicate"]] == "literal") {
                prx["literal"] = pr["literal"];
                pro = [...pro, prx];
              }
              if (this.propertyrangetypes[pr["predicate"]] == "catalogue") {
                prx["object"] = pr["literal"];
                obprops = [...obprops, prx]
              }
          }
          let thisclass = me.class_id;

          px = await add_new_entity_mention(me["class_id"], pro, obprops, this.doc_uri)
            .then((rAPI) => {
              me.already_added = rAPI.status == 200;
              me.entity_id = rAPI.data.response.uri;
              EntitiesToRefresh.add(me.entity_id);
              ClassesToRefresh.add(thisclass);
            })
            .then(this.$store.dispatch("add_entity_mention", me))
            .then(Promise.resolve("New " + me.entity_id));
          AllPromises.push(px);
        }
      }
      console.log(AllPromises.length);
      Promise.all(AllPromises).then((x) => {
        console.log("|--Finishing Entity Mentions --|\n" + x);
        this.show_green_button = false;
        this.$emit("reload-classes", ClassesToRefresh, 10, 10);
        if (EntitiesToRefresh.size > 0) {
          this.$emit("reload-entities", EntitiesToRefresh);
        }
        this.$emit("hide-message");

        for (men in this.existing_entity_mentions) {
          me = this.existing_entity_mentions[men];
          if (me.already_added) {
            continue;
          }
          console.log("Not added yet.. apparently:");
          console.log(me);
          this.show_green_button = true;
          break;
        }
        this.$refs.MentionsList.update_entities();
      });
    },
    cancelAdd(id) {
      this.existing_entity_mentions = this.existing_entity_mentions.filter(
        (todo) => todo.mention_id !== id
      );
      this.class_chooser_visible = true;
      this.show_green_button = this.existing_entity_mentions.length > 0;
    },
    addFirst(newEntityClass) {
      this.existing_entity_mentions = [
        newEntityClass,
        ...this.existing_entity_mentions,
      ];
      this.show_green_button = true;
      this.class_chooser_visible = !this.class_chooser_visible;
    },
    nextAdd(v) {
      this.class_chooser_visible = true;
      this.existing_entity_mentions = [v, ...this.existing_entity_mentions];
      this.show_green_button = this.existing_entity_mentions.length > 0;
    },
    update_list_of_mentions(newlist) {
      for (var men in newlist) {
        var newent = newlist[men];
        if (newent["class_label"] == null || newent["class_label"] == "") {
          newent["class_label"] = this.class_labels[newent["class_id"]];
        }
        this.existing_entity_mentions = [
          ...this.existing_entity_mentions,
          newent,
        ];
        this.$store.dispatch("add_entity_mention", newent);
      }

      this.$emit("reload-entities", new Set([]), 0, 0);
    },
  },
  created() {
    const roles = (localStorage.getItem('angelus-roles') || '').split(',');
    this.invalidator = roles.indexOf('invalidador') > -1;

    this.writter = localStorage.getItem('angelus-writter') === 'true';

    this.existing_entity_mentions = [];
    this.show_green_button = this.existing_entity_mentions.length > 0;
    if (typeof this.pre_existing_entities !== "undefined") {
      this.update_list_of_mentions(this.pre_existing_entities);
    }
  },
  data() {
    return {
      showSection: true, // Flag to show section
      toggleIcon: "+",
      existing_entity_mentions: [],
      class_chooser_visible: true,
      show_green_button: false,
      pre_existing_entities: [],
      onlyValidMentions: false,
      invalidator: false,
      writter: false,
    };
  },
};
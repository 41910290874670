import { mapState } from "vuex";
import { del_invalid_annotation } from "@/api/ontologias"

export default {
  name: "PropertyList",
  components: {},
  computed: mapState([
    "class_list",
    "entities_by_class_with_label",
    "property_labels",
    "entity_labels",
  ]),
  data() {
    return {
      is_deletable: false,      
    };
  },
  created() {
    if (this.deletable != null) {
      this.is_deletable = this.deletable;
    }
    console.log('***Pro', this.properties)
    this.filter_props()
  },
  props: [
    'properties',
    'deletable',
    'invalidator',
    'onlyValid',
    'mentionAlreadyAdded',
  ],
  methods: {
    make_undeletable() {
      this.is_deletable = false;
    },
    update_proplist(proplist){
        this.properties = proplist
        return this.filter_props()
    
    },
    filter_props() {
      var currproperies = this.properties
      var r = [];
      //console.log("filtering props")
      //console.log(" num properties start: "+currproperies.length)
      for (var pron in currproperies) {
        var pro = currproperies[pron]        
        if (this.property_labels[pro.predicate] == null) {
          continue;
        }
        //console.log(pro)
        if (pro.literal != null) {
          pro["object"] = pro.literal;
        }
        if (pro.catalogueitem != null) {
            //console.log("--catalogueitem "+pro.catalogueitem)
          pro["object"] = this.entity_labels[pro.catalogueitem];
        }
        r = [...r, pro];
      }
  
      return r;
    },
    async invalidMention(id, property) {
      console.log(id)
      await del_invalid_annotation(id)
      property.valid_annotation = false
    }
  },
};

import { mapState } from 'vuex';
import {onto_base_class} from '@/api/ontologias'
import PropertyHolder from '../property-holder/PropertyHolder.vue'
import PropertyList from '../property-list/PropertyList.vue'

export default {
  name: "EntityDataPropertyEditor",
  components: {
    PropertyHolder,
    PropertyList,
  },
  computed: mapState([
       'class_list',
       "entities_by_class_with_label",    
       "property_labels" ,
       "dataProperties_by_domain",
       "propertyrangetypes",      
   ]),
  props: ["entity_data"],
  methods: {
      turn_all_green(){
          this.$refs.thePropertyList.make_undeletable()
          return 0
      },
      getData_props()
      {
          var r = []
          console.log(this.dataProperties_by_domain)
          r = this.dataProperties_by_domain[this.entity_data.class_id]
          console.log("----------------")
          console.log(r)
          this.$refs.thePropertyList.filter_props()
          return r
      },
      finished(result)
      {
          this.showEditor = true
          this.data_properties_to_send = [... this.data_properties_to_send, result]
          this.entity_data["properties"] = this.data_properties_to_send           
          this.$emit('property-list-changed')
          
          console.log(" num properties  "+this.data_properties_to_send.length)
          this.$refs.thePropertyList.filter_props()           
      },
      remove_one_property(id){
          this.data_properties_to_send = this.data_properties_to_send.filter(todo => todo.id !== id)
          this.entity_data["properties"] = this.data_properties_to_send
          if (this.data_properties_to_send.length < 1)
          {
              this.entity_data["properties"] = null
          }
          this.$emit('property-list-changed')
          
          this.$refs.thePropertyList.filter_props()           
      }
  },
  /*  In data_properties_to_send
      We keep a list of "jsonified" triples of the form:
       
       {"predicate": URI,                         <- Mandatory
        "literal": Literal,                        <- Mandatory
        "specific_provenance": {                  <- Optional
            "observaciones": String,              <- Optional
            "confianza": URI,                     <- Optional
            "metodoInferencia": URI               <- Optional
        } }
       
  */
  data() {
      return {'onto_base_class': onto_base_class,
              'data_properties_to_send':[],                      
              'showEditor':true, 
              }
  }
}

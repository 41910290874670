// import pdfjsLib from "pdfjs-dist/build/pdf";
// import { PDFViewer } from "pdfjs-dist/web/pdf_viewer";
//  import "pdfjs-dist/web/pdf_viewer.css";

// pdfjsLib.GlobalWorkerOptions.workerSrc = "https://cdn.jsdelivr.net/npm/pdfjs-dist@2.0.943/build/pdf.worker.min.js";

import ImgVisualizer from "../img-visualizer/ImgVisualizer.vue"
import PdfVisualizer from "../pdf-visualizer/PdfVisualizer.vue"

import { api_doc_get, base_doc_uri, get_doc_metadata } from "@/api/ontologias"


export default {
  name: 'DocumentVisualizer',
  props: {
    docId: String,
  },

  data () {
    return {
      url: null,
      encodeUrl: null,
      isImage: false,
      metadata: null,
      page: null,
      documentPages: [],
    }
  },
  
  components: {
    ImgVisualizer,
    PdfVisualizer,
  },

  async mounted () {
    // this.fetchPDF();
    this.url = this.get_doc_url()
    await this.get_metadata()
    console.log('Docid', this.docId)
    const index = this.documentPages.findIndex(e => this.docId === e)
    this.page = index > -1 ? (index + 1) : 1
  },

  methods: {
    get_doc_uri () {
      // docuri = "<https://documentos.pruebascnb.org/ANGELUS/4L7C5D/FONCYJ>"
      return `<${base_doc_uri}${this.docId}>`   
  
    },
    get_doc_url() {                                 
      return `${api_doc_get}${this.get_doc_uri()}`
    },
    async get_metadata () {
      const metadata = await get_doc_metadata(this.get_doc_uri())

      const baseUrl = 'https://ontologias.pruebascnb.org/v1.0/'
      this.metadata = {
        title: metadata[`<${baseUrl}tieneTitulo>`],
        foliaciones: metadata[`<${baseUrl}tieneFoliacion>`],
        padre: metadata[`<${baseUrl}perteneceA>`],
        tipoDocumento: metadata['Content-Type'] ?? 'application/pdf',
        copartes: metadata[`<${baseUrl}tieneCoParte>`] ?? [],
        paginas: metadata['<http://purl.org/dc/terms/hasPart>'] ?? [],
      }

      this.isImage = this.metadata.tipoDocumento.includes('image')
      this.documentPages = this.metadata.copartes.map(p => p.split('/').slice(-2).join('/').slice(0, -1))
      console.log('METADATA', this.metadata, this.documentPages)
    },
    onPageChange(page) {
      const docid = this.documentPages[page-1]
      this.$router.push({ name: 'Anotador', params: { docid } })
      // this.$router.push({ path: `/anotador/${encodeURIComponent(this.documentPages[page-1])}` })
      location.reload()
    },
    /*fetchPDF() {
      pdfjsLib.GlobalWorkerOptions.workerSrc = './node_modules/pdfjs-dist/build/pdf.worker.js';
      console.log('Get pdf', pdfjsLib)
      const loadingTask = pdfjsLib.getDocument("https://test-apidocumentos.pruebascnb.org/document?docid=%3Chttps://documentos.pruebascnb.org/ANGELUS/QW1DJJ/5P8AU4%3E")
      console.log('traer')
      loadingTask.promise
      .then(function (doc) {
        const numPages = doc.numPages;
        console.log("# Document Loaded");
        console.log("Number of Pages: " + numPages);
        console.log();

        let lastPromise; // will be used to chain promises
        lastPromise = doc.getMetadata().then(function (data) {
          console.log("# Metadata Is Loaded");
          console.log("## Info");
          console.log(JSON.stringify(data.info, null, 2));
          console.log();
          if (data.metadata) {
            console.log("## Metadata");
            console.log(JSON.stringify(data.metadata.getAll(), null, 2));
            console.log();
          }
        });

        const loadPage = function (pageNum) {
          return doc.getPage(pageNum).then(function (page) {
            console.log("# Page " + pageNum);
            const scale = 1;
            const viewport = page.getViewport(scale);
            console.log("Size: " + viewport.width + "x" + viewport.height);
            console.log();
            console.log('oage', viewport, page);
            // const scale = 1;
            const canvas = document.getElementById('document-canvas');
            // canvas.style.display = "block";
            const context = canvas.getContext('2d');
            canvas.height = viewport.height * scale;
            canvas.width = viewport.width * scale;
            page.render({canvasContext: context, viewport: viewport});

            return page
            .getTextContent()
            .then(function (content) {
              // Content contains lots of information about the text layout and
              // styles, but we need only strings at the moment
              const strings = content.items.map(function (item) {
                return item.str;
              });
              console.log("## Text Content");
              console.log(strings.join(" "));
            })
            .then(function () {
              console.log();
            });
          });
        };
        // Loading of the first page will wait on metadata and subsequent loadings
        // will wait on the previous pages.
        for (let i = 1; i <= numPages; i++) {
          lastPromise = lastPromise.then(loadPage.bind(null, i));
        }
        return lastPromise;
      })
      .then(
        function () {
          console.log("# End of Document");
        },
        function (err) {
          console.error("Error: " + err);
        }
      )
    },*/
  },
}
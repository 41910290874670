import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import AtomicInput from "./atomic-input/AtomicInput.vue";
import { mapState } from "vuex";
import { provenancedict, sorturits } from '@/api/ontologias';
import { v4 as uuidv4 } from 'uuid'

export default {
  name: "PropertyHolder",
  props: ["subject_class", ],
  computed: mapState([
    "dataProperties_by_domain",
    "property_labels",
    "propertyrangetypes",
    "doc_uri",
    "catalogProperties_by_domain",
    "property_ranges",
  ]),
  data() {
    return {
      title: "",
      products: [],
      selector_default: { id: 0, label: "Propiedad a elegir" },
      selector_value: null,
      input_type: "",
      provdict: {},
      subject_this: null,
      object_this: null,
      provenance_this: null,
      selected_property: false,
      shouldRenderOK: false,
      result: {
        id: uuidv4(),
        literal: null,
        object: null,
        specific_provenance: null,
      },
      result_provenance: {},
      possible_properties: [{ id: 0, label: "cargando" }],
    };
  },
  methods: {
    refgen(a, b) {
      return "inputfieldref_" + a + "_" + b;
    },
    canceled_property(e) {
      e.preventDefault();
      this.selected_property = false;
      this.clear_inputs();
    },
    updateSelect(v) {
      this.selector_value = v;
      this.selected_property = true;
      this.input_type = this.propertyrangetypes[v.id];
      this.$emit("started_adding_property");
      this.result["predicate"] = v.id;
      this.$refs.inputfieldref.set_options(this.input_type, this.property_ranges[v.id], "first")
    },
    ObjectHasChanged(chng) {
      this.shouldRenderOK = chng != null && chng.length > 0;
      if (chng != null) {        
        if (this.propertyrangetypes[this.result["predicate"]] == "catalogue") {         
          this.result["catalogueitem"] = chng;    
          //console.log("This is a catalogue item "+this.result["literal"]+" "+this.result["catalogueitem"]+ " --")      
        }
        this.result["literal"]=chng
        //console.log(this.result)
        //console.log(chng)
        //console.log(".-\n")
        //this.result[this.output_type] = chng;
        
      }
    },
    clear_inputs() {
      this.selector_value = this.selector_default;
      this.provdict = provenancedict;
      this.selector_value = this.selector_default;
      this.input_type = "",
      this.subject_this = null,
      this.object_this = null,
      this.provenance_this = null,
      this.selected_property = false,
      this.shouldRenderOK = false

      this.$refs.inputfieldref.clearinput();
      // Here we recursively clear all other inputs, checking just to make sure this makes sense (ie. they
      //   have the clearinput() function defined )
      for (var ref in this.$refs) {
        var thisref = this.$refs[ref];
        if (thisref.constructor === Array) {
          for (var thisref2n in thisref) {
            var thisref2 = thisref[thisref2n];
            if (typeof thisref2.clearinput === "function") {
              thisref2.clearinput();
            }
          }
        } else {
          if (typeof thisref.clearinput === "function") {
            thisref.clearinput();
          }
        }
      }
    },
    addedProperty() {
      //console.log("emitting")
      this.$emit("propertyFinished", this.result);
      this.clear_inputs();

      //this.result[this.output_type] = null
      this.result = {
        id: uuidv4(),
        literal: null,
        predicate: null,
        object: null,
        specific_provenance: null,
      };
    },
    getProps() {
      var r = [];
      var toreturn = [];

      r = this.dataProperties_by_domain[this.subject_class];
      for (var tr in r) {
        toreturn = [
          ...toreturn,
          { id: r[tr], label: this.property_labels[r[tr]] },
        ];
      }
      r = this.catalogProperties_by_domain[this.subject_class];
      for (tr in r) {
        toreturn = [
          ...toreturn,
          { id: r[tr], label: this.property_labels[r[tr]] },
        ];
      }
      sorturits(toreturn, "id").then((rrr) => (this.possible_properties = rrr));
    },
    ProvHasChanged(event, provfield, tnum) {
      var provprops = this.provdict[provfield];
      var subf = provprops["jsonsubfields"][tnum];
      //if (event != null && event.length > 0)
      //{
      if (this.result["specific_provenance"] == null) {
        this.result["specific_provenance"] = {};
      }
      if (subf == null) {
        this.result["specific_provenance"][provprops["jsonfield"]] = event;
      } else {
        if (
          this.result["specific_provenance"][provprops["jsonfield"]] == null
        ) {
          this.result["specific_provenance"][provprops["jsonfield"]] = {};
        }
        this.result["specific_provenance"][provprops["jsonfield"]][
          subf
        ] = event;
      }
      //}
    },
  },
  components: {
    "vue-select": vSelect,
    AtomicInput: AtomicInput,
  },
  created() {
    this.selector_value = this.selector_default;
    this.provdict = provenancedict;

    //this.result[this.output_type] = null
    this.result["predicate"] = null;

    this.getProps();
  },
};
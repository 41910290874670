import { mapState } from 'vuex';
import {
  provenancedict,
  sorturits,
} from '@/api/ontologias'
import AtomicInput from '../atomic-input/AtomicInput.vue'
import vSelect from 'vue-select'
import { v4 as uuidv4 } from 'uuid'
import 'vue-select/dist/vue-select.css';

export default {
  name: "AddRelation",
  created() {
   this.provdict = provenancedict
   this.getSubjects()      
  },
  components: {     
    'vue-select': vSelect,
    AtomicInput
  },
  computed: mapState([
       'class_list', 
       "entities_by_class_with_label",
       "doc_uri",
       "existing_entity_mentions",
       "properties_by_domain",
       "properties_by_range",
       "property_labels"
       
   ]),
  props: ["item_data"],
  methods: {
       refgen(a,b){
           return "inputfieldrefrel_"+a+"_"+b
       },
       finishedRelationEditing(){           
           this.$emit("finished-relment-compose", this.result)
           //console.log(this.result)
       },
       entity_selected(v)
       {           
           this.$emit('selected-entity-finished',v )
       },
       updateSelectSubject(v){           
           this.subject_selector_value = v
           this.subjectSelected = v.id != 0
           this.result.subject = v.id
           this.result.subject_label = v.label
           this.getPredicates()
       },
       updateSelectObject(v){
           this.object_selector_value = v        
           this.objectSelected = v.id != 0
           this.result.object = v.id
           this.result.object_label = v.label
           this.getPredicates()
       },
       updateSelectPredicate(v){
           this.predicate_selector_value = v
           this.predicateSelected = v.id != 0
           this.result.predicate = v.id
           this.result.predicate_label = v.label
       },
       canceled_rel(e){
           e.preventDefault();                     
           this.clear_inputs()
       },

       getPredicates(){
           if (this.objectSelected && this.subjectSelected)
           {
               this.possible_predicates = []
               var class_object = this.object_selector_value.class
               var class_subject = this.subject_selector_value.class
               let pWithDomain = this.properties_by_domain[class_subject]
               let pWithRange = new Set(this.properties_by_range[class_object])
               let intersect = new Set([...pWithDomain].filter(i => pWithRange.has(i)));
               for (let item of intersect)
               {
                   var k = {'id': item,
                               'label': this.property_labels[item]}
                   this.possible_predicates = [... this.possible_predicates, k]
               }
           }
       },
       getSubjects(){            
           var r = this.existing_entity_mentions  
           var toreturn = {}              
           console.log("----getSubjects")    
           for (var tr in r)
           {
               var ent = r[tr]                
               toreturn[ent["entity_id"]] = { "id": ent["entity_id"],
                                           "label": ent["entity_label"],
                                           "class": ent["class_id"]}
               console.log("ent: "+ent)
           }
           var uniq = []
           for (var x of Object.values(toreturn)){
               uniq.push(x)                
           }                        
           sorturits(uniq, "id").then(rrr => this.possible_entities = rrr)
               
       },
       ProvHasChanged(event, provfield, tnum) {
           var provprops = this.provdict[provfield]
           var subf = provprops["jsonsubfields"][tnum]
           if (this.result["specific_provenance"]==null) { this.result["specific_provenance"] = {} }
           if (subf==null)
           {
               this.result["specific_provenance"][provprops["jsonfield"]] = event
           }
           else
           {
               if (this.result["specific_provenance"][provprops["jsonfield"]]==null)
               {
                   this.result["specific_provenance"][provprops["jsonfield"]] = {}
               }
               this.result["specific_provenance"][provprops["jsonfield"]][subf] = event
           }
       },
       clear_inputs(){
           this.subject_selector_value = null
           this.object_selector_value = null
           this.predicate_selector_value = null
           this.objectSelected = false
           this.subjectSelected = false
           this.predicateSelected = false        
           this.possible_predicates =  [{id:0, label: '<-Selecciona sujeto y objeto->'}]
           
           // Here we recursively clear all other inputs, checking just to make sure this makes sense (ie. they 
           //   have the clearinput() function defined )
           for (var ref in this.$refs) {
               var thisref = this.$refs[ref]
               if (thisref.constructor === Array)
               {
                   for (var thisref2n in thisref){
                       var thisref2 = thisref[thisref2n]  
                       if   (typeof thisref2.clearinput === "function") {      
                           thisref2.clearinput()    
                       }
                   }
               }
               else
               {
                   if   (typeof thisref.clearinput === "function") {      
                       thisref.clearinput()    
                   }
               }
           }
           this.result = {"mention_id": uuidv4(),
                   "subject" : null,
                   "object"  : null,
                   "predicate"  : null,
                   "subject_label" : null,
                   "object_label"  : null,
                   "predicate_label"  : null,
                   "specific_provenance": null,
                   "alreadySent": false,
                   "pre_existing":false}
       }
      
  },
  data(){
      return{
          provdict : {},
          possible_entities: [{id:0, label: 'cargando'}],
          subject_selector_value : null,      
          object_selector_value : null ,
          predicate_selector_value : null,
          possible_predicates: [{id:0, label: '<-Selecciona sujeto y objeto->'}],
          objectSelected : false,
          subjectSelected : false,
          predicateSelected : false,
          result: {"mention_id": uuidv4(),
                   "subject" : null,
                   "object"  : null,
                   "predicate"  : null,
                   "subject_label" : null,
                   "object_label"  : null,
                   "predicate_label"  : null,
                   "specific_provenance": null,
                   "alreadySent": false,
                   "pre_existing":false},
      }
  },
}
<template>
  <annotator />
</template>

<script>
  import Annotator from '../components/annotator/Annotator.vue'

  export default {
    name: 'Anotador',

    components: {
      Annotator,
    },
  }
</script>
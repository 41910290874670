import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
import { v4 as uuidv4 } from 'uuid'
import { mapState } from 'vuex';
import EntityItem from '../entity-item/EntityItem.vue';

 export default {
   name: "AddEntity",
   props: [],
   computed: mapState([
        'class_list'
    ]),
   data() {
     return {
       title: '',
       products: [ ],
       selector_default: {id: 0, name: 'Clases a elegir '},
       selector_value : null,       
       show_add_buttons: false,
       mention: {},
       all_entities: [],
       show_class_selector: true,
       show_tempent : false
     }
   },
   methods: {

     startAddingNewOfClass(e){
       e.preventDefault()
       const classToAddEntityOf = {
         class_id: this.selector_value.id,
         class_label: this.selector_value.name,
         entity_id: null,
         entity_label: null,
         mention_id: uuidv4(),
         new: true,
         finished: false,
         already_added: false,
         had_properties_altered: false
       }
       //this.$emit('added-entity-started', classToAddEntityOf);
       this.mention = classToAddEntityOf
       this.selector_value = this.selector_default
       this.show_add_buttons = false
       this.show_class_selector = false
       this.show_tempent = true
     },
     gen_empty_entity_of_selected_class(){
       var sv = this.selector_value.id
       return {
         class_id: sv,
         class_label: this.selector_value.name,
         entity_id: null,
         entity_label: null,
         mention_id: uuidv4(),
         new: false,
         finished: true,
         already_added : false,
         had_properties_altered: false
       }

     },
     startAddingExistingOfClass() {
       //e.preventDefault();
       var sv = this.selector_value.id
       var classToAddEntityOf = this.gen_empty_entity_of_selected_class()
       // Send up to parent
       //this.$emit('added-entity-started', classToAddEntityOf);
       this.mention = classToAddEntityOf                     
       this.$refs.tempentity.update_sameclass(sv)
       //this.selector_value = this.selector_default
       //this.show_add_buttons = false
       this.$refs.tempentity.shouldRenderOK = false
       this.show_tempent = true
           
       
     },
     updateSelect(v){
       console.log('update select', v)
        this.selector_value = v
        this.show_add_buttons = true        
        this.startAddingExistingOfClass()
     },
     finished_adding(v)
       {           
           v.confirmed=true           
           this.$emit('selected-entity-finished',v )    
           this.mention = {}
           this.startAddingExistingOfClass()           
           this.$refs.tempentity.reset_values()
           this.show_class_selector = true
           this.show_tempent = false
       },
     canceled_existing()
       {
           console.log("canceled existing")
           this.show_add_buttons = true
           this.mention = {}
           this.startAddingExistingOfClass()           
           this.$refs.tempentity.reset_values()
           this.show_class_selector=true
           this.show_tempent = false
       }
     
   },
   components: {
    'vue-select': vSelect,
    EntityItem
   },
   created() {
     this.selector_value = this.selector_default
     this.show_add_buttons = false
   },
   
 }